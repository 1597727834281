.app[data-v-cd04defc] {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.app .table_style[data-v-cd04defc] {
  border: 1px solid #EBEEF5;
}
.app .gridTable[data-v-cd04defc] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: calc(100% - 45px);
}
.app .gridTable .el-pagination[data-v-cd04defc] {
  padding: 10px;
}