.app[data-v-5da5cf82] {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.app .table_style[data-v-5da5cf82] {
  border: 1px solid #EBEEF5;
}
.app .gridTable[data-v-5da5cf82] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0 10px;
}
.app .gridTable .el-pagination[data-v-5da5cf82] {
  padding: 10px;
}
.echartsItem_condition[data-v-5da5cf82] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.echartsItem_condition div[data-v-5da5cf82] {
  color: #555 !important;
  font-size: 12px !important;
}
.titleItem[data-v-5da5cf82] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 20px;
}
.titleItem_lable[data-v-5da5cf82] {
  margin-right: 5px;
}
.titleItem_input[data-v-5da5cf82] {
  width: 150px;
}
.titleItem_picker[data-v-5da5cf82] {
  width: 140px;
}
.humanCententTableCentnet[data-v-5da5cf82] {
  margin-top: 15px;
}